.rightsidebar-container-show {
  width: 50rem;
  background-color: #e6f3e4;
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.3s linear;
}
.rightsidebar-container-hide {
  /* width: 50rem;
  background-color: #e6f3e4;
  display: none; */

  width: 0;
  background-color: #e6f3e4;
  visibility: hidden;
  opacity: 0;
  transition: width 0.3s linear, visibility 0s 0.3s, opacity 0.3s linear;
}
