.imageModal-Container {
  height: 98%;
}
.imageModal-header {
  display: flex;
  justify-content: flex-end;
  gap: 2rem;
}
.imageModal-imagePreview {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.imageModal-icon {
  cursor: pointer;
}
.imageModal-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
