.sidebar-header {
  width: 28rem;
  height: 4.625rem;
  background: #f0f2f5;
}
@media (max-width: 600px) {
  .sidebar-header {
    width: 100%;
  }
}
.sidebar-chatlist-searchInputLayout {
  width: 37.125rem;
  height: 5.12rem;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding-right: 1.4rem; */
}
.sidebar-header-innerContainer {
  /* width: 33rem; */
  width: 25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 1.81rem;
  margin-right: 2.25rem;
  padding-top: 0.75rem;
}
@media (max-width: 600px) {
  .sidebar-header-innerContainer {
    width: 75%;
    margin-left: 1rem;
  }
}
.sidebar-menu-container {
  display: flex;
  align-items: center;
  gap: 2.19rem;
}
@media (max-width: 600px) {
  .sidebar-menu-container {
    gap: 1.5rem;
  }
}
.sidebar-profileImg {
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 3.125rem;
  border: 2px solid #fff;
}
.sidebar-header-name {
  color: var(--Color-3, #6b7c85);
  font-family: Work Sans;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.03125rem;
  cursor: pointer;
}
.sidebar-chatlist-container {
  width: 28rem;
  height: 90vh;
  background: #fff;
  overflow-y: auto;
  overflow-x: hidden;
}
.sidebar-chatlist-searchInputContainer {
  display: flex;
  width: 30.75rem;
  height: 3.125rem;
  margin-left: 1.81rem;
  align-items: center;
  border-radius: 0.625rem;
  background: #f0f2f5;
}
.sidebar-chatlist-searchText {
  color: var(--Color-3, #6b7c85);
  font-family: Work Sans;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.02188rem;
  padding-left: 1.56rem;
  outline: none;
  width: 25rem;
  border: none;
  outline: none;
  background: #f0f2f5;
}
.sidebar-chatlist-searchIcon {
  margin-left: 1.25rem;
}
.sidebar-chatIcon {
  cursor: pointer;
}
.sidebar-menuIcon {
  cursor: pointer;
}

/* For WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 8px; /* Adjust the width as needed */
}

::-webkit-scrollbar-thumb {
  background-color: #a0a0a0;
  /* border-radius: 4px; */
}

/* For Firefox */
/* Note: 'auto' allows the browser to use its default scrollbar style */
/* You can use 'thin', 'auto', or 'none' for scrollbar-width */
body {
  scrollbar-width: thin;
}

/* Style the scrollbar thumb in Firefox */
body::-webkit-scrollbar-thumb {
  background-color: #a0a0a0;
  /* border-radius: 4px;  */
}
.sidebar-chatlist-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}
