.attachmentModal-Container {
  height: 98%;
}
.attachmentModal-header {
  display: flex;
  justify-content: flex-end;
  gap: 2rem;
}
.attachmentModal-attachmentPreview {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.attachmentModal-icon {
  cursor: pointer;
}
.attachmentModal-image {
  max-width: 50%;
  max-height: 50%;
  object-fit: contain;
}
.attachmentModal-sendIcon {
  background-color: #51a985;
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5rem;
  color: #fff;
  cursor: pointer;
}
@media (max-width: 600px) {
  .attachmentModal-sendIcon {
    width: 2.5rem;
    height: 2.5rem;
  }
}
.attachmentModal-fileName {
  color: #aebac1;
  font-family: Work Sans;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
@media (max-width: 600px) {
  .attachmentModal-fileName {
    font-size: 0.8rem;
  }
}
.attachmentModal-fileName-dark {
  color: #201922;
  font-family: Work Sans;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
