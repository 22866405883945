.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loader {
  border: 4px solid #ccc; /* Adjust the color to a greyish tone */
  border-top: 4px solid #666; /* Adjust the color to a darker grey tone */
  border-radius: 50%;
  width: 30px; /* Adjust the size as needed */
  height: 30px; /* Adjust the size as needed */
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
