.contactinfo-listContainer {
  width: 20rem;
}
.contactInfo-header {
  background-color: #f0f2f5;
  display: flex;
  align-items: center;
  padding-left: 1rem;
}
.contactInfo-header-title {
  color: #000;
  font-size: 1.5rem;
  font-family: Work Sans;
  margin-left: 1rem;
}
.contactinfo-nameContainer {
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: #f0f2f5; */
  height: 7rem;
  width: 100%;
}
.contactinfo-name {
  color: var(--Color-3, #6b7c85);
  font-family: Work Sans;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.03125rem;
}
.contactinfo-phoneNum {
  /* margin-bottom: -4rem; */
  cursor: pointer;
}
