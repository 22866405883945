.chatbox-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.chatbox-header {
  flex: 0 0 auto; /* This ensures that the header won't grow or shrink */
  width: 100%;
  height: 4.625rem;
  background: #f0f2f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chatbox-header-left {
  display: flex;
  align-items: center;
  margin-left: 2rem;
}
.chatbox-personName {
  color: var(--Color-3, #6b7c85);
  font-family: Work Sans;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.03125rem;
  margin-left: 1.81rem;
}
.chatbox-menuIcon {
  margin-right: 2.16rem;
  cursor: pointer;
}
.chatbox-chatSection {
  flex: 1;
  background: url("../../assets/images/background.png") lightgray 50% / cover
    no-repeat;
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  position: relative;
}

.chatbox-chatpad {
  width: 100%;
  height: 5.625rem;
  background: #f0f2f5;
  display: flex;
}
.chatbox-chatpad-innerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 1.4rem;
  gap: 1.6rem;
  width: 100%;
}
.chatbox-inputContainer {
  display: flex;
  width: 100%;
  height: 3.125rem;
  border-radius: 0.625rem;
  background: #fff;
  margin-right: 0.5rem;
}

.chatbox-sendBtn {
  margin-right: 2rem;
  color: #54656f;
  cursor: pointer;
}
@media (max-width: 600px) {
  .chatbox-sendBtn {
    margin-right: 0.8rem;
  }
}
.chatbox-type-input {
  color: var(--Color-3, #6b7c85);
  font-family: Work Sans;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.02188rem;
  border: none;
  outline: none;
  padding-left: 1rem;
  margin-left: 1rem;
  width: 90%;
  background-color: #fff;
}
@media (max-width: 600px) {
  .chatbox-type-input {
    padding-left: 0rem;
    width: 81%;
    font-size: 0.7rem;
  }
}
.chatbox-receivedChat {
  max-width: 30rem;
  height: fit-content;
  background: #fff;
  margin-left: 1rem;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 0.625rem;
  margin-top: 0.31rem;
}
.chatbox-textMessage {
  color: #111b21;
  font-family: Work Sans;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.02188rem;
  padding-left: 1.19rem;
  padding-right: 4rem;
  padding-top: 0.88rem;
  padding-bottom: 0.88rem;
}
.chatbox-textTime {
  color: #111b21;
  font-family: Work Sans;
  font-size: 0.5625rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.01406rem;
  margin-right: 0.5rem;
  margin-top: 2rem;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 1.12rem;
  margin-right: 2rem;
  cursor: pointer;
}
.chatbox-doubleTick {
  margin-top: 1.5rem;
  margin-right: 0.8rem;
  cursor: pointer;
}
.chatbox-downArrow {
  margin-right: 0.5rem;
}

.chatbox-sentChat {
  max-width: 30rem;
  height: fit-content;
  margin-right: 1rem;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 0.625rem;
  margin-top: 0.31rem;
  background-color: #d9fdd3;
  align-self: flex-end;
}
.chatbox-smileIcon {
  cursor: pointer;
}
.chatbox-attachIcon {
  cursor: pointer;
}
.chatbox-imageChatRec-Container {
  width: 15rem;
  min-height: 20rem;
  background-color: #fff;
  margin-top: 0.31rem;
  margin-left: 1rem;
  border-radius: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.chatbox-imageChatRec-image {
  width: 14.5rem;
  height: 19.5rem;
  border-radius: 0.625rem;
}
.chatbox-imageChatSent-Container {
  width: 15rem;
  min-height: 20rem;
  background-color: #d9fdd3;
  margin-top: 0.31rem;
  margin-left: 1rem;
  border-radius: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  align-self: flex-end;
  position: relative;
}
.chatbox-imageChatSent-downArrow {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 1rem;
  margin-top: 0.8rem;
}
.chatbox-imageChatSent-image {
  width: 14.5rem;
  height: 19.5rem;
  border-radius: 0.625rem;
}
.chatbox-videoChatRec-Container {
  width: 15rem;
  min-height: 20rem;
  background-color: #fff;
  margin-top: 0.31rem;
  margin-left: 1rem;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.chatbox-videoChatRec-bottomContainer {
  width: 90%;
  display: flex;
  /* align-self: center; */
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
}
.chatbox-videoChatSent-Container {
  width: 15rem;
  min-height: 20rem;
  background-color: #d9fdd3;
  margin-top: 0.31rem;
  margin-left: 1rem;
  border-radius: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  align-self: flex-end;
  position: relative;
}
.chatbox-videoChatSent-downArrow {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 1rem;
  margin-top: 0.8rem;
}
.chatbox-videoChatRec-durationContainer {
  display: flex;
  align-items: center;
}
.chatbox-videoChatRec-video {
  width: 14.5rem;
  height: 19.5rem;
  border-radius: 0.625rem;
}
.chatbox-videoChatRec-duration {
  color: #fff;
  font-size: 0.7rem;
}
.chatbox-videoChatRec-time {
  color: #fff;
  font-size: 0.7rem;
}
.chatbox-videoChatRec-downloadContainer {
  display: flex;
  background-color: #85817c;
  align-items: center;
  width: 5.5rem;
  justify-content: center;
  border-radius: 2rem;
  position: absolute;
}
.chatbox-videoChatRec-downloadText {
  color: #fff;
  margin-left: 0.3rem;
  font-size: 0.8rem;
}
.chatbox-videoChatSent-downloadContainer {
  display: flex;
  background-color: #85817c;
  align-items: center;
  width: 5.5rem;
  justify-content: center;
  border-radius: 2rem;
  position: absolute;
}
.chatbox-videoChatSent-downloadText {
  color: #fff;
  margin-left: 0.3rem;
  font-size: 0.8rem;
}
.chatbox-videoChatSent-video {
  width: 14.5rem;
  height: 19.5rem;
  border-radius: 0.625rem;
}
.chatbox-videoChatSent-bottomContainer {
  width: 90%;
  display: flex;
  /* align-self: center; */
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
}
.chatbox-videoChatSent-durationContainer {
  display: flex;
  align-items: center;
}
.chatbox-videoChatSent-duration {
  color: #fff;
  font-size: 0.7rem;
}
.chatbox-videoChatSent-time {
  color: #fff;
  font-size: 0.7rem;
  cursor: pointer;
}
.chatbox-audioChatRec-container {
  width: 15rem;
  min-height: 3.5rem;
  border-radius: 0.625rem;
  background-color: #fff;
  margin-top: 0.31rem;
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.chatbox-audioChatRec-timeContainer {
  display: flex;
  width: 60%;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  margin-bottom: -0.4rem;
  left: 3rem;
}
.chatbox-audioChatRec-iconContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chatbox-audioChatRec-duration {
  color: #9c8d8d;
  font-size: 0.8rem;
}
.chatbox-audioChatRec-time {
  color: #9c8d8d;
  font-size: 0.8rem;
}
.chatbox-audioChatSent-container {
  width: 15rem;
  min-height: 3.5rem;
  border-radius: 0.625rem;
  background-color: #d9fdd3;
  margin-top: 0.31rem;
  margin-right: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  align-self: flex-end;
}
.chatbox-audioChatSent-downArrow {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 2rem;
  margin-top: 0.3rem;
}
.chatbox-audioChatSent-iconContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.chatbox-audioChatSent-timeContainer {
  display: flex;
  width: 60%;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  margin-bottom: -0.4rem;
  left: 3rem;
  cursor: pointer;
}
.chatbox-audioChatSent-duration {
  color: #9c8d8d;
  font-size: 0.8rem;
}
.chatbox-audioChatSent-time {
  color: #9c8d8d;
  font-size: 0.7rem;
}
.chatbox-attachmentRec-Container {
  width: 18rem;
  min-height: 6rem;
  background-color: #fff;
  margin-top: 0.31rem;
  margin-left: 1rem;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
}
.chatbox-attachmentRec-wrapper {
  background-color: #d1f4cc;
  display: flex;
  height: 4.2rem;
  width: 98%;
  margin-top: 0.2rem;
  border-radius: 0.625rem;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: space-between;
}
.chatbox-attachmentRec-detailcontainer {
  display: flex;
  align-items: center;
  margin-top: -1.3rem;
  gap: 0.3rem;
}
.chatbox-attachmentRec-textContainer {
  display: flex;
  flex-direction: column;
}
.chatbox-attachmentRec-fileName {
  font-size: 0.9rem;
}
.chatbox-attachmentRec-timeContainer {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 0.8rem;
  margin-bottom: -0.3rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.chatbox-attachmentRec-time {
  color: #111b21;
  font-family: Work Sans;
  font-size: 0.5625rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.chatbox-attachmentRec-detailText {
  color: #79909b;
  font-size: 0.7rem;
}
.chatbox-attachmentRec-wrapper-left {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.chatbox-attachmentSent-Container {
  width: 18rem;
  min-height: 6rem;
  background-color: #d9fdd3;
  margin-top: 0.31rem;
  margin-right: 1rem;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  position: relative;
  align-self: flex-end;
  cursor: pointer;
}
.chatbox-attachmentSent-wrapper {
  background-color: #d1f4cc;
  display: flex;
  height: 4.2rem;
  width: 98%;
  margin-top: 0.2rem;
  border-radius: 0.625rem;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  position: relative;
}
.chatbox-attachmentSent-detailcontainer {
  display: flex;
  align-items: center;
  margin-top: -1.3rem;
  gap: 0.3rem;
}
.chatbox-attachmentSent-textContainer {
  display: flex;
  flex-direction: column;
}
.chatbox-attachmentSent-fileName {
  font-size: 0.9rem;
  width: 8rem;
}
.chatbox-attachmentSent-timeContainer {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 0.8rem;
  margin-bottom: -0.3rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.chatbox-attachmentSent-time {
  color: #111b21;
  font-family: Work Sans;
  font-size: 0.5625rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  margin-bottom: -0.3rem;
}
.chatbox-attachmentSent-statusIcon {
  margin-bottom: 0.5rem;
}
.chatbox-attachmentSent-detailText {
  color: #79909b;
  font-size: 0.7rem;
}
.chatbox-attachmentSent-wrapper-left {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.chatbox-chatSection-downArrow {
  background-color: #fff;
  width: 3rem;
  height: 3rem;
  border-radius: 30rem;
  position: fixed;
  bottom: 6rem;
  right: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, color 0.3s ease;
}
.chatbox-contactRec-Container {
  width: 18rem;
  /* min-height: 6rem; */
  height: fit-content;
  background-color: #fff;
  margin-top: 0.31rem;
  margin-left: 1rem;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
}
.chatbox-contactRec-wrapper {
  background-color: #d1f4cc;
  flex-direction: column;
  /* height: 4.2rem; */
  height: 95%;
  width: 98%;
  margin-top: 0.2rem;
  border-radius: 0.625rem;
  display: flex;
  align-self: center;
  margin-bottom: 1.4rem;
}
.chatbox-contact-individual {
  display: flex;
}
.chatbox-contactRec-text {
  font-size: 1rem;
  color: #000;
  margin-left: 1rem;
  font-family: Work Sans;
  font-weight: 500;
  margin-right: 1rem;
}
.chatbox-contactRec-phoneNum {
  font-size: 0.8rem;
  color: #000;
  margin-left: 1rem;
  font-family: Work Sans;
  font-weight: 500;
  margin-right: 1rem;
}
.chatbox-contactRec-timeContainer {
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 0.8rem;
  margin-bottom: -0.3rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}
.chatbox-contactRec-time {
  color: #111b21;
  font-family: Work Sans;
  font-size: 0.5625rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.chatbox-contactSent-Container {
  width: 18rem;
  min-height: 6rem;
  background-color: #d9fdd3;
  margin-top: 0.31rem;
  margin-right: 1rem;
  border-radius: 0.625rem;
  display: flex;
  align-self: flex-end;
  flex-direction: column;
  position: relative;
  cursor: pointer;
}
.chatbox-attachmentSent-downArrow {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 4rem;
  margin-top: 0.8rem;
}
.chatbox-contactRec-downArrow {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 1rem;
  margin-top: 0.8rem;
}

.chatbox-imageChatRec-time {
  color: #fff;
  font-size: 0.7rem;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 1rem;
}
.chatbox-imageChatSent-time {
  color: #fff;
  font-size: 0.7rem;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 1rem;
  cursor: pointer;
}

.chatbox-mapPreview {
  width: 15rem;
  min-height: 10rem;
  background-color: #fff;
  margin-top: 0.31rem;
  margin-left: 1rem;
  border-radius: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
}

.chatbox-mapPreview-sender {
  width: 15rem;
  min-height: 10rem;
  background-color: #d9fdd3;
  margin-top: 0.31rem;
  margin-left: 1rem;
  border-radius: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  align-self: flex-end;
  position: relative;
  flex-direction: column;
}
.chatbox-mapPreview-inner {
  background-color: #d9fdd3;
  width: 97%;
  height: 97%;
  border-top-right-radius: 0.625rem;
  border-top-left-radius: 0.625rem;
  margin-top: 0.15rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chatbox-mapPreview-inner iframe {
  border: 0;
  width: 100%;
  height: 100%;
}
.chatbox-mapPreview-textContainer {
  /* background-color: #e1e2e3; */
  width: 97%;
  height: 20%;
  margin-bottom: 0.15rem;
  border-bottom-right-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
  display: flex;
  justify-content: space-between;
}
.chatbox-mapPreview-title {
  color: #000;
  font-size: 0.7rem;
  margin-left: 0.4rem;
}
.chatbox-mapPreview-time {
  color: #000;
  font-size: 0.7rem;
  margin-right: 0.7rem;
  cursor: pointer;
}
.chatbox-mapPreview-time-sent {
  color: #000;
  font-size: 0.7rem;
  margin-right: 2rem;
  cursor: pointer;
}
.chatbox-mapPreview-statusIcon {
  position: absolute;
  right: 0;
  bottom: 0;
  margin-bottom: 0.3rem;
}
.chatbox-sticker {
  width: 15rem;
  min-height: 15rem;
  background-color: #fff;
  margin-top: 0.31rem;
  margin-left: 1rem;
  border-radius: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
}
.chatbox-sticker-sender {
  width: 15rem;
  min-height: 15rem;
  background-color: #d9fdd3;
  margin-top: 0.31rem;
  margin-left: 1rem;
  border-radius: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  align-self: flex-end;
  position: relative;
  flex-direction: column;
}
.chatbox-sticker-inner {
  background-color: #fff;
  width: 97%;
  height: 97%;
  border-radius: 0.625rem;
  margin-top: 0.15rem;
  overflow: hidden;
}

.chatbox-sticker-time {
  color: #000;
  font-size: 0.7rem;
  align-self: flex-end;
  margin-right: 0.4rem;
  cursor: pointer;
}
.chatbox-unknownType {
  width: 15rem;
  min-height: 15rem;
  background-color: #fff;
  margin-top: 0.31rem;
  margin-left: 1rem;
  border-radius: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-direction: column;
}
.chatbox-unknownType-sender {
  width: 15rem;
  min-height: 15rem;
  background-color: #d9fdd3;
  margin-top: 0.31rem;
  margin-left: 1rem;
  border-radius: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
  align-self: flex-end;
  position: relative;
  flex-direction: column;
}
.chatbox-unknownType-inner {
  background-color: #fff;
  width: 97%;
  height: 97%;
  border-radius: 0.625rem;
  margin-top: 0.15rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.chatbox-unknownType-strigified {
  width: 97%;
}
.chatbox-unknownType-time {
  color: #000;
  font-size: 0.7rem;
  align-self: flex-end;
  margin-right: 0.4rem;
  cursor: pointer;
}
