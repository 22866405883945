.messageInfo-container {
  background-image: url("../../assets/images/background.png");
  background-size: cover;
}
.messageinfo-listContainer {
  width: 20rem;
}
.messageInfo-header {
  background-color: #f0f2f5;
  display: flex;
  align-items: center;
  padding-left: 1rem;
  width: 20rem;
}
.messageInfo-header-title {
  color: #000;
  font-size: 1.5rem;
  font-family: Work Sans;
  margin-left: 1rem;
}

.messageInfo-status-container {
  background-color: #f0f2f5;
  width: 100%;
  height: 10.3rem;
  margin-top: 5rem;
  display: flex;
}
.messageInfo-status-innerContainer {
  background-color: #fff;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  margin-top: 0.6rem;
}
.messageInfo-horizontalLine {
  width: 90%;
  height: 0.0625rem;
  background: #e9edef;
  margin-left: 1rem;
}
.messageInfo-status-Read {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}
.messageInfo-status-Delivered {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}
.messageInfo-status-DeliveredText {
  display: flex;
  align-items: center;
  gap: 0.7rem;
}
.messageInfo-status-ReadText {
  display: flex;
  align-items: center;
  gap: 0.7rem;
}
.messageInfo-status-deliveredTime {
  margin-top: -1rem;
  font-size: 0.8rem;
}
.messageInfo-status-readTime {
  margin-top: -1rem;
  font-size: 0.8rem;
}
.messageInfo-status-failedReason {
  font-size: 0.8rem;
  width: 18rem;
  margin-top: -0.6rem;
}

.messageInfo-status-reasonContainer {
  display: flex;
  align-items: center;
  margin-top: -0.5rem;
  gap: 0.2rem;
}
