.settings-list {
  width: 28rem;
  padding: 0;
}

.settings-header {
  width: 100%;
  height: 5rem;
  background-color: #3c806a;
  display: flex;
  align-items: center;
  padding-left: 1rem;
}
.settings-header-title {
  color: #fff;
  font-size: 1.5rem;
  font-family: Work Sans;
  margin-left: 1rem;
}
.settings-nameContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 2rem;
}
@media (max-width: 600px) {
  .settings-nameContainer {
    width: 80%;
  }
}

.settings-personName {
  color: var(--Color-3, #6b7c85);
  font-family: Work Sans;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.03125rem;
  margin-left: 0.4rem;
}
.settings-personPhone {
  color: var(--Color-3, #6b7c85);
  font-family: Work Sans;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.03125rem;
  margin-left: 0.4rem;
  cursor: pointer;
}
.settings-button-text {
  margin-left: 1rem;
}
.settings-companyName-container {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}
.settings-companyNumber-container {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}
.settings-person-container {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  margin-top: 1rem;
}
.settings-personNumber-container {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}
.settings-horizontalLine {
  width: 23rem;
  height: 0.0625rem;
  background: #e9edef;
  margin-left: 2rem;
}
.settings-personName-edit {
  color: var(--Color-3, #6b7c85);
  font-family: Work Sans;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.03125rem;
  margin-left: 0.4rem;
  border-radius: 0.1875rem;
  border: none;
  border-bottom: 1px solid #667781;
  /* height: 3rem; */
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.settings-personPhone-edit {
  color: var(--Color-3, #6b7c85);
  font-family: Work Sans;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.03125rem;
  margin-left: 0.4rem;
  cursor: pointer;
  border-radius: 0.1875rem;
  border: none;
  border-bottom: 1px solid #667781;
  /* height: 3rem; */
  margin-top: 1rem;
  margin-bottom: 1rem;
}
