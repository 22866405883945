.editProfile-Container {
  /* height: 30rem; */
  width: 24rem;
}
@media (max-width: 600px) {
  .editProfile-Container {
    width: 20rem;
  }
}
.editProfile-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.editProfile-TitlePreview {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.editProfile-btn-container {
  display: flex;
  gap: 2rem;
  justify-content: flex-end;
  background-color: #ecf0f1;
  width: 100%;
  margin-top: 1rem;
}
.editProfile-title {
  color: var(--Color-3, #3e3e3e);
  font-family: Work Sans;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.03125rem;
}
.editProfile-text {
  color: var(--Color-3, #6b7c85);
  font-family: Work Sans;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.03125rem;
  margin-left: 1.81rem;
}
.editProfile-btn-cancel {
  background-color: #b0c1c6;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  cursor: pointer;
  border-radius: 0.2rem;
}
.editProfile-btn-yes {
  background-color: #e85d5d;
  height: 2rem;
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  cursor: pointer;
  border-radius: 0.2rem;
}
.editProfile-form {
  display: flex;
  flex-direction: column;
}
.editProfile-inputTitle {
  color: var(--Color-3, #3e3e3e);
  font-family: Work Sans;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.03125rem;
}
.editProfile-input {
  color: var(--Color-3, #6b7c85);
  font-family: Work Sans;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.03125rem;
  padding-left: 0.3rem;
  outline: none;
  border: none;
  width: 90%;
}
.editProfile-inputContainer {
  display: flex;
  align-items: center;
  width: 90%;
  height: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid var(--Neutral-Color-03, #e5e5e5);
  background: var(--Main-White-Color, #fff);
}
@media (max-width: 600px) {
  .editProfile-inputContainer {
    height: 0.5rem;
  }
}
.editProfile-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}
