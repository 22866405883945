.confirmationModal-Container {
  /* height: 30rem; */
  width: 24rem;
}
@media (max-width: 600px) {
  .confirmationModal-Container {
    width: 20rem;
  }
}
.confirmationModal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.confirmationModal-TitlePreview {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.confirmationModal-btn-container {
  display: flex;
  gap: 2rem;
  justify-content: flex-end;
  background-color: #ecf0f1;
  width: 100%;
}
.confirmationModal-title {
  color: var(--Color-3, #6b7c85);
  font-family: Work Sans;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.03125rem;
  margin-left: 1.81rem;
}
.confirmationModal-text {
  color: var(--Color-3, #6b7c85);
  font-family: Work Sans;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.03125rem;
  margin-left: 1.81rem;
}
.confirmationModal-btn-cancel {
  background-color: #b0c1c6;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  cursor: pointer;
  border-radius: 0.2rem;
}
.confirmationModal-btn-yes {
  background-color: #e85d5d;
  height: 2rem;
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  cursor: pointer;
  border-radius: 0.2rem;
}
