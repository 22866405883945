.reel-player-container {
  position: relative;
  width: 100%;
  max-width: 15rem;
  height: 20rem;
}

.reel-player {
  width: 100%;
  height: 20rem;
  display: block;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  z-index: 1;
}
