.chatlist-chatContainer {
  width: 28rem;
  height: 5.44rem;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}
@media (max-width: 600px) {
  .chatlist-chatContainer {
    width: 100%;
  }
}
.chatlist-chatContainer:hover {
  background-color: #e0e0e0;
}
.chatlist-chatContainer-selected {
  width: 28rem;
  height: 5.44rem;
  background: #e0e0e0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}
@media (max-width: 600px) {
  .chatlist-chatContainer-selected {
    width: 100%;
  }
}
.chatlist-UpperSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 2rem;
  width: 24rem;
  height: 1.4rem;
  margin-top: 1.25rem;
}
@media (max-width: 600px) {
  .chatlist-UpperSection {
    margin-left: 0.5rem;
    width: 80%;
  }
}
.chatlist-personName {
  color: var(--Color-3, #6b7c85);
  font-family: Work Sans;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.03125rem;
}
.chatlist-personName-unread {
  color: var(--Color-3, #000);
  font-family: Work Sans;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.03125rem;
}
.chatlist-message {
  color: var(--Color-3, #6b7c85);
  font-family: Work Sans;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.025rem;
  width: 19rem;
}
.chatlist-message-unread {
  color: var(--Color-3, #000);
  font-family: Work Sans;
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.025rem;
  width: 19rem;
}
.chatlist-time {
  color: var(--Color-3, #6b7c85);
  font-family: Work Sans;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.02188rem;
}
.chatlist-time-unread {
  color: var(--Color-3, #25d366);
  font-family: Work Sans;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.02188rem;
}
.chatlist-lowerSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 2rem;
  width: 24rem;
  height: 1.4rem;
  margin-bottom: 1.25rem;
}
@media (max-width: 600px) {
  .chatlist-lowerSection {
    margin-left: 0.5rem;
    width: 80%;
  }
}
.chatlist-lastMessage {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}
.chatlist-UnreadMsgContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.625rem;
  width: 2rem;
  height: 1.625rem;
  background: var(--Green-1, #25d366);
}
.chatlist-UnreadMessages {
  color: #fff;
  font-family: Work Sans;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.02188rem;
}
.chatlist-verticalLine {
  width: 23rem;
  height: 0.0625rem;
  background: #e9edef;
  margin-left: 2rem;
}
