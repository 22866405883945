.newChat-list {
  width: 100%;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.newChat-header {
  width: 28rem;
  height: 5rem;
  background-color: #3c806a;
  display: flex;
  align-items: center;
  padding-left: 1rem;
}
.newChat-header-title {
  color: #fff;
  font-size: 1.5rem;
  font-family: Work Sans;
  margin-left: 1rem;
}

.newChat-button-text {
  margin-left: 1rem;
}
.newChat-listItem {
  display: flex;
  flex-direction: column;
}
.newChat-listItem:hover {
  background-color: #e0e0e0;
}
.newChat-verticalLine {
  background: #e9edef;
  width: 90%;
  height: 0.0625rem;
  margin-left: 1rem;
}
