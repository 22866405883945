.login-layout {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #54b159;
}
.login-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25rem;
  height: 30rem;
  background: #2b2d31;
  border-radius: 1rem;
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.08);
}
@media (max-width: 600px) {
  .login-form-container {
    height: 100vh;
    border-radius: 0;
    justify-content: center;
  }
}
.login-form-appName {
  color: #57b656;
  font-family: Inter;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: -1rem;
}
.login-form-title {
  color: #fff;
  font-family: Inter;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
@media (max-width: 600px) {
  .login-form-title {
    font-size: 1.5rem;
  }
}
.login-form-inputTitle {
  color: #fff;
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.login-form-inputLayout {
  width: 90%;
}
@media (max-width: 600px) {
  .login-form-inputLayout {
    margin-left: 0;
  }
}
.login-form-inputContainer {
  display: flex;
  align-items: center;
  width: 90%;
  height: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  border: 1px solid var(--Neutral-Color-03, #e5e5e5);
  background: var(--Main-White-Color, #fff);
}
@media (max-width: 600px) {
  .login-form-inputContainer {
    height: 0.5rem;
  }
}
.login-form-inputText {
  outline: none;
  border: none;
  width: 90%;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 0.5rem;
}
.login-form-submit {
  display: flex;
  margin-top: 2.5rem;
  width: 80%;
  height: 2rem;
  padding: 0.75rem 1rem;
  justify-content: center;
  align-items: center;
  border-radius: 10rem;
  background-color: #57b656;
  cursor: pointer;
}
@media (max-width: 600px) {
  .login-form-submit {
    height: 1rem;
  }
}
.login-form-submitText {
  color: var(--Main-White-Color, #fff);
  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
